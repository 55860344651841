<template>
  <div
    :class="[
      'vertical-split',
      'flex',
      'flex-col',
      'lg:flex-row',
      handleScreenSize,
    ]"
  >
    <PurpleHexContainer class="max-w-1/2 w-full" style="min-height: 128px">
      <div
        class="relative block lg:flex items-center justify-center min-h-full"
      >
        <BambeeLogo
          v-if="noLogo"
          class="block mx-auto mb-6 mt-6 lg:mb-0 lg:mt-0 lg:absolute lg:top-6 lg:left-16"
        />
        <slot name="left" />
      </div>
    </PurpleHexContainer>
    <div class="bg-white max-w-1/2 w-full h-full lg:min-h-full overflow-y-auto lg:py-10">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import BambeeLogo from '@/components/BambeeLogo.vue';
import PurpleHexContainer from '@/components/PurpleHexContainer.vue';

export default {
  name: 'LayoutVertical',
  components: { BambeeLogo, PurpleHexContainer },
  props: {
    noLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      screenHeight: window.screen.height,
      handleScreenSize: this.screenHeight < 768 ? 'lg:h-full' : 'lg:min-h-screen',
    };
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler);
  },
  destroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      const newScreenHeight = window.screen.height;
      this.handleScreenSize =
        newScreenHeight < 800 ? 'lg:h-full' : 'lg:min-h-screen';
    },
  },
};
</script>
