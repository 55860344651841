<template>
  <div class="h-screen flex flex-col bg-gray-7">
    <header v-if="showHeader" class="bg-white flex-shrink-0 px-4 py-2 sm:px-8 sm:py-4 border-b border-gray-5">
      <slot name="header">
        <!-- Default -->
        <BambeeLogo class="block h-10 sm:h-12 mx-auto text-primary" />
      </slot>
    </header>

    <main class="flex-grow overflow-auto px-4 py-6 sm:px-12 sm:py-8"
      :class="{ 'pt-7 sm:pt-9': !showHeader && !$slots.newheader }">
      <div class="space-y-3 sm:space-y-4 max-w-4xl mx-auto" :class="{
        ' mb-6': $slots.headline && !$slots.subheadline,
        'mb-8': $slots.subheadline,
      }">
        <TypeDisplay v-if="$slots.headline" :variant="$matchMedia.sm ? 'tera' : 'tera'"
          class="text-center sm:font-display-medium font-display-small" tag="h1">
          <slot name="headline"></slot>
        </TypeDisplay>

        <TypeHeading v-if="$slots.subheadline" :variant="$matchMedia.sm ? 'headline' : 'subtitle'" weight="regular"
          class="text-center" tag="h3">
          <slot name="subheadline"></slot>
        </TypeHeading>
      </div>

      <div class=" max-w-5xl mx-auto">
        <slot></slot>
      </div>
    </main>

    <footer v-if="$slots.footer"
      class="bg-white flex-shrink-0 px-4 py-2 sm:px-8 sm:py-4 border-t border-gray-5 flex flex-col items-center">
      <slot name="footer"></slot>
    </footer>
  </div>

</template>

<script>
import BambeeLogo from '@/components/BambeeLogo.vue';
import { TypeDisplay, TypeHeading, TypeBody } from '@bambeehr/pollen';

export default {
  name: 'LayoutApplicationStyle',
  components: {
    BambeeLogo,
    TypeDisplay,
    TypeHeading,
    TypeBody,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
