<template>
  <div
    v-if="info"
    class="navigation-panel shadow-2 font-poppins"
    @mouseleave.stop="$emit('set-panel', null)"
  >
    <div class=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 whitespace-normal md:max-w-7xl md:mx-auto">
      <div
        class="bg-[#F5F6FF] flex flex-col justify-center items-start p-10 lg:pr-16 border-r border-gray-6 "
      >
        <div class="lg:max-w-sm w-full">
          <div
            class="font-poppins text-20 xl:text-24 font-semibold text-gradient"
          >
            {{ info.title }}
          </div>
          <div class="font-poppins text-14 xl:text-16 mt-4 text-gray-3">
            {{ info.body }}
          </div>
        </div>
      </div>

      <div
        class="p-10 xl:p-20 flex flex-col space-y-6  lg:col-span-2 bg-white"
      >
        <div class="text-20 xl:text-24 text-gray-0 border-b border-gray-5 pb-6">
          {{ info.itemTitle }}
        </div>
        <div class="grid lg:grid-cols-2 gap-8">
          <div v-for="(subitem, id) in info.items" :key="id">
            <div
              class="font-poppins text-14 xl:text-18 text-gray-0 font-medium"
            >
              {{ subitem.title }}
            </div>
            <div class="font-poppins text-12 xl:text-16 text-gray-3">
              {{ subitem.body }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseDivider, TypeOverline } from '@bambeehr/pollen';
import { NavPanels } from '../../constants';
import NavigationPanelItem from './NavigationPanelItem.vue';
import { defineComponent } from '@nuxtjs/composition-api';

// Need to delete a bunch of unused code from this file but don't have the time right now.

const panelNames = Object.freeze({
  MANAGER: 'manager',
  ABOUT: 'about',
});

const panelLabels = Object.freeze({
  FOR_YOUR_EMPLOYEES: 'FOR YOUR EMPLOYEES',
  FOR_YOUR_BUSINESS: 'FOR YOUR BUSINESS',
  WHO_WE_ARE: 'WHO WE ARE',
});

export default defineComponent({
  name: 'NavigationPanel',

  components: {
    BaseDivider,
    NavigationPanelItem,
    TypeOverline,
  },
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const panelClasses = (label) => {
      let classes = 'col-span-12 lg:col-span-6';
      if (
        props.panel === panelNames.MANAGER &&
        label === panelLabels.FOR_YOUR_EMPLOYEES
      ) {
        classes = 'col-span-12 lg:col-span-5';
      }
      if (
        props.panel === panelNames.MANAGER &&
        label === panelLabels.FOR_YOUR_BUSINESS
      ) {
        classes = 'col-span-12 lg:col-span-7';
      }

      if (
        props.panel === panelNames.ABOUT &&
        label === panelLabels.WHO_WE_ARE
      ) {
        classes = 'col-span-12 lg:col-span-12';
      }

      return classes;
    };

    const panelItemClasses = (label) => {
      let classes = 'col-span-6 lg:col-span-6';
      if (
        props.panel === panelNames.MANAGER &&
        label === panelLabels.FOR_YOUR_EMPLOYEES
      ) {
        classes = 'col-span-12';
      }
      if (
        props.panel === panelNames.MANAGER &&
        label === panelLabels.FOR_YOUR_BUSINESS
      ) {
        classes = 'col-span-6 lg:col-span-4';
      }

      if (
        props.panel === panelNames.ABOUT &&
        label === panelLabels.WHO_WE_ARE
      ) {
        classes = 'lg:col-span-3 lg:my-2 col-span-4 my-0 max-w-[15rem]';
      }

      return classes;
    };

    const { autopilotsBusiness, hrManager, payroll, about, resources } =
      NavPanels;

    return {
      panelClasses,
      panelItemClasses,
      autopilotsBusiness,
      hrManager,
      payroll,
      about,
      resources,
    };
  },
  computed: {
    navPanel() {
      switch (this.panel) {
        case 'autopilot':
          return this.autopilotsBusiness;
        case 'manager':
          return this.hrManager;
        case 'payroll-addon':
          return this.payroll;
        case 'about':
          return this.about;
        case 'resources':
          return this.resources;
        default:
          return [];
      }
    },
  },
});
</script>

<style scoped>
.navigation-panel {
  background-image: linear-gradient(90deg, #F5F6FF 50%,#fff 50%);
  color: black;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05),
    0px 4px 8px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.05);
  transition: opacity 100ms ease-in-out;
  z-index: 30;
}

.text-gradient {
  color: #c686ff;
  background-image: linear-gradient(
    45deg,
    #c686ff 18%,
    #6e85f7 52%,
    #e6b8db 89%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
