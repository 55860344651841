export const navItems = [
  {
    key: 'autopilot',
    desktop: 'HR Autopilot',
    tablet: 'HR Autopilot',
  },
  {
    key: 'manager',
    desktop: 'HR Manager',
    tablet: 'HR Manager',
  },
  {
    key: 'payroll-addon',
    desktop: 'Bambee Guided Payroll',
    tablet: 'Payroll',
  },
  {
    key: 'about',
    desktop: 'Who We Are',
    tablet: 'About',
  },
  {
    key: 'resources',
    desktop: 'Resources',
    tablet: 'Resources',
  },
];

export const fauxNavItems = [
  {
    key: 'dedicated',
    desktop: 'Dedicated HR Manager™',
    tablet: 'Dedicated HR Manager™',
    subtitle: `& Meet Goals`,
  },
  {
    key: 'bionic',
    desktop: 'Bionic HR Guidance™',
    tablet: 'Bionic HR Guidance™',
    subtitle: `& Stay Compliant`,
  },
  {
    key: 'compliance',
    desktop: 'Neural Compliance™',
    tablet: 'Neural Compliance™',
    subtitle: `& Optimize Performance`,
  },
  {
    key: 'handsOn',
    desktop: 'Hands-On HR™',
    tablet: 'Hands-On HR™',
    subtitle: `& Boost Productivity`,
  },
];

export const HeroContent = {
  title: `Put your HR on Autopilot`,
  subtitle: `plus a Dedicated HR Manager for $9/mo`,
  benefits: [
    { text: `Your Own Dedicated HR Manager` },
    { text: `HR Compliant Policies` },
    { text: `Employee Onboarding & Terminations` },
    { text: `Relief From Complex HR Issues` },
    { text: `Annual & Comprehensive HR Audits` },
    { text: `$9/mo for companies up to 500 employees` },
    { text: `Work Off Your Plate & Peace of Mind!` },
  ],
};

export const VideoHeader = {
  title: `How Does Bambee Work?`,
  subtitle: `With the help of your dedicated HR Manager, Bambee puts your HR on
    autopilot to streamline your HR, and automate your onboarding, policies,
    and even employee training.`,
};

export const Factoids = [
  {
    factoid: `Bambee clients are 4x less likely to have a complaint filed against them.`,
  },
  {
    quote: `As a small business, Bambee was exactly what we needed for HR. Affordable, professional, and friendly! We absolutely recommend them.`,
    image: `/assets/images/market/beautiful-people/BryanKelly@2x.png`,
    fullname: `Bryan Kelly`,
    title: `Owner & Vice President @ HOODZ Cleaning`,
  },
  {
    quote: `We are a growing company that needs help with our HR. We have hired Bambee and thus far have been very happy. Our HR Rep has been attentive and quick to respond with valuable HR knowledge. We are building our HR policies and processes way quicker than I alone would have been able to do.`,
    image: `/assets/images/market/beautiful-people/male-4.svg`,
    fullname: `Damon P`,
    title: `CFO in Leading Telecommunications Company`,
  },
  {
    factoid: `Only 12 percent of U.S. employees strongly agree that their company does a good job of onboarding new employees.`,
  },
];

export const Automations = [
  {
    id: 'autopilot-audit',
    image: '/assets/images/market/autopilot-identify.svg',
    header: '01 HR AUDITS',
    title: 'Quickly Identify Your HR Gaps',
    body: `HR rules change. Audits let you identify any HR gaps, and then we help you with an action plan to get your internal practices up-to-date to keep protecting your company and employees.`,
  },
  {
    id: 'autopilot-policy',
    image: '/assets/images/market/preview-policy.webp',
    header: '02 HR POLICIES',
    title: 'Policies That Protect Your Business and Standardize Your Culture',
    body: `HR Autopilot makes sure all core, protective policies are current, signed by your employees, and reaffirmed semi-annually.  Then, your dedicated HR Manager crafts any custom HR policies your business needs.`,
  },
  {
    id: 'autopilot-training',
    image: '/assets/images/market/autopilot-training.webp',
    header: '03 TRAINING & CERTIFICATIONS',
    title: 'Training That Keeps Your Staff Compliant and Productive',
    body: `Bambee takes care of important and often-mandatory training like sexual harassment, workplace safety, and business ethics - and reports back to you on everyone’s progress.`,
  },
  {
    id: 'autopilot-reportcard',
    image: '/assets/images/market/autopilot-reportcard.webp',
    header: '04 STAFF PERFORMANCE TRACKING',
    title: 'Deliver Feedback to Improve Employee Performance',
    body: `Bambee’s Report Cards help you track your staff against their goals, and open up a regular dialogue to give praise, constructive feedback, or take corrective action.`,
  },
  {
    id: 'autopilot-voices',
    image: '/assets/images/market/autopilot-voices.webp',
    header: '05 UNDERSTAND EMPLOYEE MORALE',
    title: 'Create A Space For Employees to Speak Their Thoughts',
    body: `Employee Voices lets your employees share concerns and alert you to potential problems.  But it doesn’t have to be bad news - it’s also a great way for your staff to express gratitude, anonymously or publicly.`,
  },
  {
    id: 'autopilot-cabinet',
    image: '/assets/images/market/autopilot-signature.webp',
    header: '06 COMPLIANT DOCUMENT STORAGE',
    title: 'Comply with EEOC Document Retention Standards',
    body: `Federal & state laws mandate that certain company files be held for a certain period of time.  Plus, never guess who signed what - or where your important HR documents are.  They’re all gathered safely in your Smart Cabinet.`,
  },
];

export const DedicatedHrList = [
  {
    icon: 'doorOpenSolid',
    title: 'Employee Relations & HR Problem Solving',
    body: 'The most sensitive issues require rich dialogue.  Your HR Manager engages with you to create the right outcomes for your business.',
  },
  {
    icon: 'clipboardCheckSolid',
    title: 'Craft HR Policies, Procedures & Shape Company Culture',
    body: 'Your HR Manager builds policies specific for your businesses based on labor regulation and your unique company culture for better alignment.',
  },
  {
    icon: 'badgeDollarSolid',
    title: 'Onboarding, Terminations & Navigating Labor Regulations',
    body: `Lean on Bambee for onboarding, terminations, and corrective actions and get clarity on all your regulatory questions.`,
  },
  {
    icon: 'binoculars',
    title: 'Employer Coaching & Guidance',
    body: 'Your HR Manager gets to know you on a first name basis and gives you full remote HR support plus coaching where you need it.',
  },
];

export const EmployeeAutopilot = {
  leftItems: [
    {
      icon: 'clipboardCheck',
      title: 'Automating Compliance Reviews Keeps Companies on Track',
      body: 'People want to work at companies that are making best efforts to follow fair HR practices and create rules-based environments.',
    },
    {
      icon: 'clipboardCheck',
      title: 'Understanding HR Policy Creates Alignment',
      body: 'Employees do better when they understand the rules around the culture they contribute to.',
    },
    {
      icon: 'postitCheck',
      title: 'Coaching Helps Employees Reach Professional Goals',
      body: 'Every employee deserves to know how they’re performing at their jobs and how they can perform better in their role',
    },
  ],
  rightItems: [
    {
      icon: 'presentation',
      title: 'In App Training Empowers Employees With Workplace Knowledge',
      body: 'Giving employees the tools to follow the rules increases the likelihood they do so.',
    },
    {
      icon: 'megaphone',
      title: 'Responding to Feedback Holds the Company Accountable',
      body: 'Resentment is the number one contributing factor to poor performance and labor claims. Giving employees a safe place to voice concerns is key to addressing them.',
    },
  ],
};

export const HowLowPrices = {
  items: [
    {
      icon: 'clipboardCheckSolid',
      title: 'Small Business Focused',
      body: 'We only service businesses with less than 500 employees. That means we deal with your types of needs all day, every day.',
    },
    {
      icon: 'doorOpenSolid',
      title: 'The Best of Modern Tech',
      body: 'We use cutting edge data technology to stay informed about labor laws & help you avoid mistakes.',
    },
  ],
  rightItems: [
    {
      icon: 'binoculars',
      title: 'HR Practice Automation',
      body: 'HR Autopilot automates your key HR practices to keep you HR compliant all year through.',
    },
  ],
};

export const fauxNavPanels = {
  dedicated: {
    title: `Dedicated HR Manager and the tools to guide you faster than problems can surface. (No, really).`,
    body: `Experience the personal touch of your Dedicated HR Manager™—a true HR expert who understands your business and proactively guides you through every challenge. From tailored compliance reviews and policy updates to managing terminations and navigating tough conversations, your HR Manager is by your side to ensure smooth operations and mitigate risks before they escalate. With this hands-on approach, you can focus on growing your business while we handle the complexities of HR.`,
    itemTitle: `What to expect from your Dedicated HR Manager™`,
    items: [
      {
        id: 'scheduled-hr-calls',
        title: `Scheduled HR Calls`,
        body: `20-minute tactical calls or 40-minute strategic sessions with your dedicated HR Manager.`,
      },
      {
        id: 'hr-guidance-direct-max',
        title: `HR Guidance Direct™ Max`,
        body: `The powerful HR Guidance tool Bambee’s Dedicated HR managers use—now at your fingertips.`,
      },
      {
        id: 'comprehensive-hr-guidance',
        title: `Comprehensive HR Guidance`,
        body: `Uses Bambee’s proprietary 8-step methodology to diagnose issues, assess risks, and resolve.`,
      },
      {
        id: 'step-by-step-hr-action-plans',
        title: `Step-by-Step HR Action Plans`,
        body: `Clear structured directives that guide business owners and managers through HR execution.`,
      },
      {
        id: 'hr-policy-compliance-review',
        title: `HR Policy & Compliance Review`,
        body: `Your HR Manager reviews new policy with you to outline risks, benefits and implementation strategy`,
      },
      {
        id: 'business-strategy-series',
        title: `Business Strategy Series`,
        body: `Business owners define performance and culture goals, collaborating with their HR Manager to strategize and execute a structured HR plan.`,
      },
    ],
  },
  bionic: {
    title: `Bionic HR Guidance™: Drafting, Detecting & Delivering Compliance`,
    body: `With Bambee's Bionic HR Platform, we continuously scan your workforce for friction, misalignment, and risks. Our HR Intelligence drafts compliant policies and targeted action plans—whether it's managing employee inquiries, stepping in proactively, rehabilitating performance issues, or resolving high-risk complaints. When needed, your dedicated HR Manager intervenes to mediate before problems escalate, keeping your business aligned and compliant.`,
    itemTitle: `What to expect from Bionic HR Guidance™`,
    items: [
      {
        title: `Employee Relationship Management (ERP)`,
        body: `Employees can ask Bambee HR-related questions about  time off, attendance, and company policies, and Bambee responds on behalf of the business.`,
      },
      {
        title: `Proactive HR Intervention`,
        body: `HR Managers step in before conflicts escalate, reducing employer friction and resolving workplace issues.`,
      },
      {
        title: `Cognitive Restoration™ `,
        body: `HR Managers help managers address performance  concerns, deliver corrective actions, and give structured feedback.`,
      },
      {
        title: `High Risk Complaint Resolution`,
        body: `Expert HR support for harassment claims, discrimination complaints, hostile work environments, and compliance-sensitive matters.`,
      },
      {
        title: `Cognitive Recognition™`,
        body: `Insights-powered employee feedback and engagement tracking help business owners detect culture issues early.`,
      },
    ],
  },
  compliance: {
    title: `Turn HR Into Offense – Find and Fix Issues Before They Grow.`,
    body: `Turn HR into offense with Neural Compliance™. Powered by next-generation intelligence, Neural Compliance™ doesn’t just monitor your workforce—it anticipates compliance risks before they manifest. Unlike routine oversight, it analyzes every interaction within Bambee to detect subtle signs of friction, misalignment, and emerging legal concerns. When potential issues surface, Neural Compliance™ delivers immediate, actionable insights and early-warning alerts so your HR team can preempt problems with precision.`,
    itemTitle: `What to expect from Neural Compliance™`,
    items: [
      {
        title: `Employee Relationship Management (ERP)`,
        body: `Employees can ask Bambee HR-related questions about time off, attendance, and company policies, and Bambee responds on behalf of the business.`,
      },
      {
        title: `Performance & Feedback Tools`,
        body: `Includes Performance Improvement Plans (PIPs), direct manager coaching, and structured accountability 
systems.`,
      },
      {
        title: `Manager Performance Optimization`,
        body: `HR Managers help managers address performance  concerns, deliver corrective actions, and give structured feedback.`,
      },
      {
        title: `Automatic Issue Flagging`,
        body: `Bambee’s HR system identifies potential performance or workplace concerns and helps develop a plan to resolve them`,
      },
      {
        title: `Workplace Radar`,
        body: `Automatic employee feedback and engagement tracking help business owners detect culture issues early.`,
      },
      {
        title: `HR Advisor`,
        body: `Gives employees direct access to HR, allowing them to request time off, ask a policy question, report concerns, or submit workplace requests and responds for you.`,
      },
    ],
  },
  handsOn: {
    title: `Total HR Oversight. Proactive Risk Protection with HR Intelligence.`,
    body: `Transform your business with Hands-On HR™. Our expert HR Managers develop custom policies that reflect your unique culture while ensuring full compliance. Bambee brings these policies to life with HR Execution Playbooks—step-by-step guides that drive seamless implementation and company buy-in. With Smart Match Development, we align coaching, compliance, and skill development to support your HR practices. From policy creation to managed execution, Hands-On HR™ delivers total oversight and proactive risk protection through HR Intelligence, empowering your organization to execute flawless HR strategies and stay competitive.`,
    itemTitle: `What to expect from Hands-On HR™`,
    items: [
      {
        title: `Custom HR Policy Development`,
        body: `Custom HR Policy Development – HR Managers create policies tailored to a company’s culture while ensuring full compliance.`,
      },
      {
        title: `Managed Implementation`,
        body: `Bambee implements HR practices and guidance, ensuring seamless execution and compliance.`,
      },
      {
        title: `Bambee HR Intelligence`,
        body: `When implementing a new policy, Bambee provides a step-by-step plan to gain full company buy-in.`,
      },
      {
        title: `HR Execution Playbooks*`,
        body: `Tactical step by step guides on how to successfully create change in your org across any topic.`,
      },
      {
        title: `HR Practices Plans`,
        body: `When implementing a new policy, Bambee provides a step-by-step plan to gain full company buy-in.`,
      },
      {
        title: `Smart Match Devevelopment*`,
        body: `Intelligently assigns coaching, compliance, and skill development.  `,
      },
    ],
  },
};

export const NavPanels = {
  autopilotsBusiness: [
    {
      label: `FOR YOUR BUSINESS`,
      items: [
        {
          id: 'top-nav-hr-autopilot-hr-audits',
          title: 'HR Audits',
          body: 'Learn where your HR gaps are',
          scrollId: 'autopilot-audit',
        },
        {
          id: 'top-nav-hr-autopilot-training-certifications',
          title: 'Training & Certifications',
          body: 'Keeping your staff compliant',
          scrollId: 'autopilot-training',
        },
        {
          id: 'top-nav-hr-autopilot-hr-policies',
          title: 'HR Policies',
          body: 'Core protective documents and customized policies',
          scrollId: 'autopilot-policy',
        },
        {
          id: 'top-nav-hr-autopilot-cabinet',
          title: 'Cabinet',
          body: 'Storing and securing important HR documents',
          scrollId: 'autopilot-cabinet',
        },
      ],
    },
    {
      label: `FOR YOUR EMPLOYEES`,
      items: [
        {
          id: 'top-nav-hr-autopilot-employee-feedback',
          title: 'Employee Feedback',
          body: `So your employees know how they're doing`,
          scrollId: 'autopilot-reportcard',
        },
        {
          id: 'top-nav-hr-autopilot-employee-voices',
          title: 'Employee Voices',
          body: 'Giving your team an outlet to speak up',
          scrollId: 'autopilot-voices',
        },
      ],
    },
  ],
  hrManager: [
    {
      label: `FOR YOUR BUSINESS`,
      items: [
        {
          id: 'top-nav-hr-manager-ongoing-relationship',
          title: 'Ongoing Relationship',
          body: 'There for you through the whole year',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-custom-hr-policies',
          title: 'Custom HR Policies',
          body: 'Policies to fit your specific needs',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-onboarding',
          title: 'Employee Management',
          body: 'Bringing on and dismiss employees the compliant way',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-navigate-labor-regulations',
          title: 'Navigate Labor Regulations',
          body: 'Helping to keep your company safe',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-phone-email-chat-support',
          title: 'Phone, Email, and Chat Support',
          body: 'Communication on your terms',
          scrollId: 'hrm-available',
        },
      ],
    },
    {
      label: `FOR YOUR EMPLOYEES`,
      items: [
        {
          id: 'top-nav-hr-manager-employee-relations',
          title: 'Employee Relations',
          body: 'Heard employees are happy employees',
          scrollId: 'dedicated-manager',
        },
        {
          id: 'top-nav-hr-manager-field-employee-concerns',
          title: 'Field Employee Concerns',
          body: 'Taking care of concerns before they become complaints',
          scrollId: 'dedicated-manager',
        },
      ],
    },
  ],
  payroll: [
    {
      label: `KEY FEATURES`,
      items: [
        {
          id: 'top-nav-bambee-payroll-2-day-payroll',
          title: '2-Day Payroll',
          body: 'Get your employees paid quickly with direct deposit',
          scrollId: 'payroll-addon',
        },
        {
          id: 'top-nav-bambee-payroll-automatic-taxes',
          title: 'Automatic Taxes',
          body: 'Bambee takes care of federal, state, and local',
          scrollId: 'payroll-addon',
        },
        {
          id: 'top-nav-bambee-payroll-employee-misclassification-protection',
          title: 'Employee Misclassification Protection',
          body: 'Part time? Full time? Contractor?  We can help.',
          scrollId: 'payroll-addon',
        },
      ],
    },
    {
      label: `HR MANAGER SUPPORT`,
      items: [
        {
          id: 'top-nav-bambee-payroll-wage-compliance-guidance',
          title: 'Wage & Compliance Guidance',
          body: 'Avoid costly miscalculations',
          scrollId: 'payroll-addon',
        },
      ],
    },
  ],
  about: [
    {
      label: `WHO WE ARE`,
      items: [
        {
          id: 'top-nav-bambee-about',
          title: 'About',
          body: 'Our mission is big & our people are bold',
          link: '/about',
        },
        {
          id: 'top-nav-bambee-about-leadership',
          title: 'Leadership',
          body: 'Our leadership team is ambitious, direct, and proven',
          link: '/about/leadership',
        },
        {
          id: 'top-nav-bambee-about-teams',
          title: 'Teams',
          body: 'Teams that believe in dialog, in-person work, and results',
          link: '/about/teams',
        },
        {
          id: 'top-nav-bambee-about-culture',
          title: 'Culture',
          body: 'We’re straight forward and proud of who we are',
          link: '/about/culture',
        },
        {
          id: 'top-nav-bambee-about-investors',
          title: 'Investors',
          body: 'We’re building a game changing American brand',
          link: '/about/investors',
        },
        {
          id: 'top-nav-bambee-about-hr-managers',
          title: 'HR Managers',
          body: 'We deliver HR with empathy to small business',
          link: '/about/hr-managers',
        },
        {
          id: 'top-nav-bambee-careers',
          title: 'Careers',
          body: 'Join Bambee',
          link: 'about/careers',
        },
      ],
    },
  ],
  resources: [
    {
      label: `RESOURCES`,
      items: [
        {
          id: 'top-nav-bambee-blog',
          title: 'Blog',
          body: 'See the latest in industry trends and topics',
          link: '/blog',
        },
      ],
    },
  ],
};

export default {
  DedicatedHrList,
  EmployeeAutopilot,
  Factoids,
  HeroContent,
  navItems,
  NavPanels,
  VideoHeader,
};
