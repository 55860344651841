















































































import { BaseButton } from '@bambeehr/pollen';
import { computed, useRoute, ref } from '@nuxtjs/composition-api';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebookF as FacebookIcon } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter as TwitterIcon } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faYoutube as YouTubeIcon } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faLinkedinIn as LinkedInIcon } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import CompletePaymentBanner from '@/modules/SplashPage/components/CompletePaymentBanner.vue';
import useGlobalInfo from '@/hooks/useGlobalInfo';
import WVPPBanner from '@/components/WVPPBanner/WVPPBanner.vue';

export default {
  components: {
    CompletePaymentBanner,
    BaseButton,
    FontAwesomeIcon,
    WVPPBanner,
  },
  props: {
    items: {
      type: Array,
      default: () => [
        {
          title: 'How we help',
          links: [
            {
              link: '/compliance',
              text: 'Labor Regulations & Compliance',
            },
            {
              link: '/terminations',
              text: 'Terminations',
            },
            {
              link: '/industries',
              text: 'Industries',
            },
            {
              link: '/faq',
              text: 'Frequently Asked Questions',
            },
          ],
        },
        {
          title: 'Platform',
          links: [
            {
              link: '/product',
              text: 'Core Features',
            },
            {
              link: '/plans',
              text: 'Plans & Pricing',
            },
          ],
        },
        {
          title: 'About Bambee',
          links: [
            {
              link: 'https://app.bambee.com',
              text: 'Sign In',
            },
            {
              link: '/about',
              text: 'About',
            },
            {
              link: 'https://boards.greenhouse.io/bambee',
              text: 'Careers',
            },
            {
              link: '/legal',
              text: 'Legal',
            },
            {
              link: '/reviews',
              text: 'Reviews',
            },
            {
              link: 'https://bambee.partnerstack.com/?group=baselinepartners',
              text: 'Partner',
            },
          ],
        },
        {
          title: 'Urgent HR need?',
        },
      ],
    },
    socialLinks: {
      type: Array,
      default: () => [
        {
          title: 'Facebook',
          icon: FacebookIcon,
          link: 'http://www.facebook.com/bambeeHQ/',
        },
        {
          title: 'Twitter',
          icon: TwitterIcon,
          link: 'http://www.twitter.com/BambeeHQ',
        },
        {
          title: 'YouTube',
          icon: YouTubeIcon,
          link: 'http://www.youtube.com/watch?v=rzJhkTm7baY&t=1s',
        },
        {
          title: 'LinkedIn',
          icon: LinkedInIcon,
          link: 'http://www.linkedin.com/company/bambee/',
        },
      ],
    },
    legalLinks: {
      type: Array,
      default: () => [
        {
          link: '/legal/privacy',
          text: 'Privacy Policy',
        },
        {
          link: '/legal/tos',
          text: 'Terms & Conditions',
        },
        {
          link: '/legal/retention',
          text: 'Retention Policy',
        },
      ],
    },
  },
  setup() {
    const { bambeeHours } = useGlobalInfo();

    const year = computed(() => new Date().getFullYear());

    return {
      year,
      bambeeHours,
      showWVPPBanner: ref(true),
    };
  },
};
