<template>
  <div
    class="home-nav z-20 bg-white"
    :class="[
      themeColor.backgroundColor,
      banner ? 'has-banner' : '',
      mobileMenuToggle ? 'w-full sm:static fixed' : isFixed,
    ]"
  >
    <client-only>
      <component
        :is="banner"
        v-if="!offsetTop"
        :brand="brand"
        :white-logo="whiteLogo"
        :white-logo-size="whiteLogoSize"
        :promotion="promotion"
      />
    </client-only>
    <div class="group z-40 text-white">
      <nav :class="topNavTrigger ? 'shadow-md bg-white' : ''">
        <div
          :class="legacyPages ? bannerPadding : 'py-4 sm:py-0'"
          class="flex items-center justify-between max-w-7xl mx-auto px-4 lg:px-6 py-4 bg-white"
        >
          <div class="flex items-center">
            <BambeeLogo
              class="bambee-logo mr-1 md:mr-4"
              :class="themeColor.logoColor"
            />
            <div v-if="!legacyPages" class="flex items-center justify-end bg-white">
              <div
                v-for="(item, index) in navItems"
                :key="`nav-item-${index}`"
                class="hidden sm:flex sm:items-center nav-item-container whitespace-nowrap h-20 px-1 sm:px-2 lg:px-4 xl:px-6 border-t-4 border-b-4 border-t-transparent border-b-transparent bg-white"
                :class="[
                  panel === item.key ? 'is-active' : '',
                  backgroundColor === 'white'
                    ? 'text-black hover:border-b-basePrimary'
                    : 'text-white hover:border-b-white',
                ]"
                @click.stop="sendTo(item.key)"
                @mouseenter="setPanel(item.key)"
                @touchstart="setPanel(item.key)"
                @touchcancel="setPanel(null)"
              >
                <div
                  v-if="hideNavOptions"
                  id="nav-human-resource"
                  class="nav-item"
                >
                  <TypeHeading
                    class="hidden lg:block font-poppins text-13 text-gray-3 hover:text-gray-1"
                    variant="headline"
                    weight="strong"
                  >
                    <span>{{ item.desktop }}</span>
                    <br />
                    <span
                      class="font-poppins text-12 text-gray-3 font-medium"
                      v-if="item.subtitle"
                      >{{ item.subtitle }}</span
                    >
                  </TypeHeading>
                  <TypeHeading
                    class="tracking-tight block lg:hidden text-13 text-gray-1"
                    variant="subtitle"
                    weight="strong"
                  >
                    <span>{{ item.desktop }}</span>
                    <br />
                    <span
                      class="font-poppins text-12 text-gray-3 font-medium"
                      v-if="item.subtitle"
                      >{{ item.subtitle }}</span
                    >
                  </TypeHeading>
                </div>
                <!-- <NavigationPanel
                  style="z-index: -1"
                  class="nav-panel bg-white"
                  :panelHeading="panelHeading"
                  :panelItemTitle="panelItemTitle"
                  :panelItemBody="panelItemBody"
                  :class="isColor"
                  :panel="panel"
                  @set-panel="setPanel(null)"
                /> -->
                <FauxNavPanel
                  style="z-index: -1"
                  class="nav-panel bg-white"
                  :info="fauxPanelInfo"
                  @set-panel="setPanel(null)"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center">
            <client-only>
              <div
                class="relative actions sm:flex flex-wrap justify-center items-center mr-2"
                :class="{ hidden: isHamburger }"
              >
                <BaseButton
                  v-if="isText"
                  :class="[themeColor.loginText]"
                  :flat="true"
                  class="mr-2"
                  @click="sendTo('app')"
                >
                  {{ text }}
                </BaseButton>
                <BaseButton
                  variant="secondary"
                  @click="goToTryBambeeNow()"
                  v-show="isButton"
                >
                  {{ buttonText }}
                </BaseButton>
              </div>
            </client-only>
            <MobileMenuToggle
              v-if="isHamburger"
              :toggle="mobileMenuToggle"
              class="block sm:hidden mobile-menu-toggle"
              :color="backgroundColors"
              @toggle="toggleMobileMenu"
            />
          </div>
        </div>
      </nav>
      <MobileMenu
        v-if="mobileMenuToggle"
        class="block sm:hidden"
        :panelItemTitle="panelItemTitle"
        :panelItemBody="panelItemBody"
        :show="hideNavOptions"
        :text="buttonText"
        :mobileMenuButtonText="mobileMenuButtonText"
        :color="backgroundColors"
        @set-panel="setPanel($event)"
      />
    </div>
    <!-- <div class="text-[36px] text-10"></div> -->
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useRoute,
  useRouter,
  onBeforeMount,
} from '@nuxtjs/composition-api';
import {
  AvatarImage,
  BaseButton,
  TypeHeading,
  constants,
} from '@bambeehr/pollen';
import BambeeLogo from '@/modules/HomePage/components/Navigation/BambeeLogo.vue';
import NavigationPanel from './NavigationPanel.vue';
import FauxNavPanel from './FauxNavPanel.vue';
import MobileMenu from './MobileMenu.vue';
import MobileMenuToggle from '@/components/Navigation/MobileMenuToggle.vue';
import Campaign, {
  promotionType,
} from '@/modules/SplashPage/constants/Campaign';
import Partners from '@/modules/SplashPage/constants/Partners';
import {
  fauxNavItems as navItems,
  fauxNavPanels,
} from '@/modules/HomePage/constants';
import useGlobalSettings from '@/hooks/useGlobalSettings';
import usePartnerPromo from '@/modules/SplashPage/hooks/usePartnerPromo';
import useMatchMedia from '@/hooks/useMatchMedia';

const FreeTrialBanner = () =>
  import('@/modules/SplashPage/components/FreeTrial/FreeTrialBanner.vue');
const RadioBanner = () =>
  import('@/modules/SplashPage/components/RadioBanner.vue');
const PartnerBanner = () =>
  import('@/modules/SplashPage/components/Partners/PartnerBanner.vue');
const { Typography } = constants;

export default defineComponent({
  name: 'HomeNav',
  Typography,
  components: {
    AvatarImage,
    BambeeLogo,
    BaseButton,
    MobileMenu,
    MobileMenuToggle,
    NavigationPanel,
    TypeHeading,
    FauxNavPanel,
  },
  props: {
    whiteLogo: {
      type: String,
      default: '',
    },
    whiteLogoSize: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      required: false,
    },
    promotion: {
      type: String,
      default: promotionType.SETUP_FEE_DISCOUNT,
    },
    buttonText: {
      type: String,
      default: 'Try Now',
    },
    showNavOptions: {
      type: String,
      default: 'true',
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    mobileMenuButtonText: {
      type: String,
      default: 'Log In',
    },
    text: {
      type: String,
      default: 'Sign In',
    },
    showButton: {
      type: String,
      default: 'true',
    },
    showText: {
      type: String,
      default: 'true',
    },
    showHamburger: {
      type: String,
      default: 'true',
    },
    makeNavBarFixed: {
      type: String,
      default: 'false',
    },
  },
  setup(props) {
    const { isAPartnerPath, shouldShow, fetchPartners } =
      usePartnerPromo(props);
    const panel = ref(null);
    const offsetTop = ref(0);
    const route = useRoute();
    const router = useRouter();
    const mobileMenuToggle = ref(false);
    const navigationPanelToggle = ref(false);
    const { getHeader } = useGlobalSettings();
    const toggleNav = ref(false);
    const setPanel = (key) => {
      // testing purposes only
      if (!key) {
        return;
      }

      if (key) {
        navigationPanelToggle.value = true;
      } else {
        navigationPanelToggle.value = false;
        mobileMenuToggle.value = false;
      }
      panel.value = key;
    };

    const fauxPanelInfo = computed(() => {
      return fauxNavPanels[panel.value];
    });

    const matchMedia = useMatchMedia();
    const topNavTrigger = computed(
      () =>
        offsetTop.value > 0 ||
        mobileMenuToggle.value ||
        navigationPanelToggle.value ||
        toggleNav.value
    );

    const sendTo = (key) => {
      // eslint-disable-next-line default-case
      switch (key) {
        case 'app':
          window.location.href = `https://${process.env.APP}`;
          break;
        default:
          break;
      }
    };
    onBeforeMount(async () => {
      shouldShow.value = await fetchPartners(props.companyName);
    });

    const tryBambee = computed(() =>
      matchMedia.value.xl ? 'Try Bambee Now' : 'Try Now'
    );

    const goToTryBambeeNow = () => {
      router.push('/registration/company-size/');
    };

    const onScroll = () => {
      offsetTop.value =
        window.pageYOffset || document.documentElement.scrollTop;
    };

    onMounted(() => {
      window.addEventListener('scroll', onScroll);
      window.addEventListener('touchstart', onScroll);
      window.addEventListener('touchmove', onScroll);
      offsetTop.value =
        window.pageYOffset || document.documentElement.scrollTop;
      const { topNavToggle } = getHeader;
      toggleNav.value = topNavToggle;
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('touchstart', onScroll);
      window.removeEventListener('touchmove', onScroll);
    });

    const toggleMobileMenu = () => {
      mobileMenuToggle.value = !mobileMenuToggle.value;
    };

    const banner = computed(() => {
      if (route.value.name === Campaign.FREE_TRIAL) {
        return FreeTrialBanner;
      }
      if (
        (route.value.query.utm_medium === 'sem-br' &&
          route.value.name === Campaign.RADIO) ||
        (route.value.name === Campaign.RADIO &&
          route.value.query.utm_medium !== 'sem-br')
      ) {
        return RadioBanner;
      }
      if (isAPartnerPath.value) {
        return PartnerBanner;
      }

      return null;
    });

    const brand = computed(
      () => props.companyName || route.value.params.brand || route.value.name
    );

    const legacyPages = computed(() => {
      if (
        [
          'terminations',
          'onboarding',
          'radio',
          'hr-policies',
          'square',
        ].includes(route.value?.name) ||
        [
          Partners.DEEL,
          Partners.REMOTE,
          Partners.BUILT_FIRST,
          Partners.BREX,
          Partners.PAYROLLSETUP,
        ].includes(route.value?.params?.brand) ||
        route.value?.params?.brand ||
        props.companyName
      ) {
        return true;
      }

      return false;
    });

    const bannerPadding = computed(() => {
      if (!topNavTrigger.value) {
        return 'pt-2 pb-4 sm:py-2 in-if';
      }

      return 'py-4 sm:py-2 in-else';
    });

    const hideNavOptions = computed(() => props.showNavOptions === 'true');
    const themeColor = computed(() => {
      const theme = {
        backgroundColor:
          props.backgroundColor === 'dark' ? 'bg-brandPrimary' : 'bg-white',
        loginText:
          props.backgroundColor === 'dark' ? 'text-white' : 'text-basePrimary',
        hamburgerMenuColor:
          props.backgroundColor === 'dark' ? 'text-white' : 'text-basePrimary',
        logoColor: 'text-basePrimary',
      };

      if (props.backgroundColor === 'dark') {
        theme.logoColor = 'text-white';
        theme.loginText = 'text-white';
      }

      return theme;
    });

    const backgroundColors = computed(() => {
      return props.backgroundColor === 'dark' ? 'white' : 'primary';
    });

    const isButton = computed(() => {
      return props.showButton === 'true';
    });

    const isText = computed(() => {
      return props.showText === 'true';
    });

    const isHamburger = computed(() => {
      return props.showHamburger === 'true';
    });

    const isFixed = computed(() => {
      return props.makeNavBarFixed === 'true' ? 'fixed w-full' : 'sticky';
    });

    const isColor = computed(() => {
      return backgroundColors.value === 'primary'
        ? 'bg-white'
        : 'bg-brandPrimary';
    });

    const isColorPanel = (element) => {
      const colors = backgroundColors.value;

      if (element === 'panelHeading') {
        return computed(() =>
          colors === 'white' ? 'text-secondaryBrandDefault' : 'text-black'
        );
      }

      if (element === 'panelItemBody') {
        return computed(() =>
          colors === 'white' ? 'text-white' : 'text-grayLight'
        );
      }

      if (element === 'panelItemTitle') {
        return computed(() =>
          colors === 'white' ? 'text-white' : 'text-grayDark'
        );
      }
    };

    const panelHeading = isColorPanel('panelHeading');
    const panelItemTitle = isColorPanel('panelItemTitle');
    const panelItemBody = isColorPanel('panelItemBody');

    return {
      navItems,
      setPanel,
      sendTo,
      panel,
      goToTryBambeeNow,
      onScroll,
      offsetTop,
      mobileMenuToggle,
      toggleMobileMenu,
      navigationPanelToggle,
      topNavTrigger,
      banner,
      brand,
      legacyPages,
      tryBambee,
      bannerPadding,
      hideNavOptions,
      themeColor,
      backgroundColors,
      isColor,
      panelHeading,
      panelItemTitle,
      panelItemBody,
      isButton,
      isText,
      isHamburger,
      isFixed,
      fauxPanelInfo,
      fauxNavPanels,
    };
  },
});
</script>
<style scoped>
.nav-panel {
  background-color: #f7f7f7;
  left: 0;
  padding-top: 80px;
  position: fixed;
  right: 0;
  top: 0;
  transition: max-height 400ms ease-in-out;
  display: none;
}

.nav-item-container:hover .nav-panel {
  display: block;
}
@media screen and (max-width: 968px) {
  .bambee-logo >>> svg {
    height: 35px;
    width: 120px;
  }
}

@media screen and (max-width: 320px) {
  .bambee-logo >>> svg {
    height: 20px;
    width: 90px;
  }
}
</style>
